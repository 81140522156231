<template>
    <div>
		<b-form-textarea
			:placeholder="placeholder"
			:value="content"
			@input="onValueChange"
			:required="required"
		/>
    </div>
</template>

<script type="text/javascript">
	export default {
		name: "RawLongtextInput",
        props:[
            'placeholder',
            'z_id',
            'content',
            'required',
            'variable_type'
        ],
        data() {
            return {
                
            }
        },
        methods: {
            onValueChange(val) {		
                this.$emit('change', { key: this.z_id, val: val, type: 'RawLongtextInput' })
            },
        }
    }
</script>
